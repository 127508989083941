/*
    Customize scss
*/
/*
    When nesting scss if the classes are in the same tag or modifier (e.g. :hover) use an & before (e.g. &:hover).
    Classes or tags that are nested within another tag do not need an &.
*/

/*
    If there is missing scss check the old diploma-sender.css file first. I left code that I couldn't find a place it was being used.
    TODO: KW: Remove when diploma-sender.css file is gone
*/

/*a*/
a {
    .card-link.disabled {
        color: var(--bs-body-color);
        cursor: default;
    }

    &:not([href]):not([class]),
    &:not([href]):not([class]):hover {
        color: var(--bs-link-color);
        text-decoration: underline;
    }
}

/*Alert*/
.alert {
    margin-bottom: 0.5rem;

    &:not(.alert-dismissible) {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }
}

/*Dropdown*/
.dropdown-item {
    cursor: pointer;
}

/*Dropzone*/
.dropzone {
    cursor: pointer;
    display: block;
    height: 10em;
    position: relative;
    width: 100%;

    &.drag {
        background-color: var(--bs-secondary-bg);
    }

    div {
        display: block;
        margin-top: 1rem;
        text-align: center;
    }

    input {
        cursor: pointer;
        height: 100%;
        margin: 0;
        opacity: 0;
        outline: none;
        padding: 0;
        position: absolute;
        width: 100%;
    }
}

/*Form*/
.form-check-label {
    line-height: 1.5;
}

.form-floating-disabled~label::after {
    background-color: var(--bs-secondary-bg) !important;
}

.form-floating-disabled {
    background-color: var(--bs-secondary-bg);
}

.form-floating-invalid {
    border-color: var(--bs-danger);
}

.form-floating-label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
    transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group-icon {
    align-items: center;
    display: flex;
    height: calc(3.5rem + calc(var(--bs-border-width)* 2));
    font-size: 1.25rem;
    justify-content: center;
    min-height: calc(3.5rem + calc(var(--bs-border-width)* 2));
    width: 3.5rem;
}

.message-text {
    white-space: pre-wrap;
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

textarea {
    height: 24em !important;
    resize: none;
}

/*Layout*/
.big-icon {
    font-size: 15em;
}

blockquote,
.blockquote {
    font-size: 80%;
    margin: 0 0 0 0.8ex;
    border-left: 1px solid var(--bs-border-color);
    padding-left: 1ex;
}

main {
    min-height: 100vh !important;
}

.w-48 {
    width: 3rem;
}

/*Nav*/
.navbar {
    --bs-gutter-x: .75rem;
    padding-left: var(--bs-gutter-x);
    padding-right: var(--bs-gutter-x);
}

.nav-link,
.nav-item {
    color: var(--bs-body-color);

    &:not(.active):hover {
        cursor: pointer;
    }
}

.nav-tabs {
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-top: 0.25rem;
}

/*Offcanvas Nav*/
.offcanvas-end {
    overflow-x: auto;
    overflow-y: auto;
}

.offcanvas-body li {
    padding: .25rem .5rem;
    background-color: transparent;

    a {
        color: var(--bs-body-color);
        padding: .1875rem .5rem;
        margin-top: .125rem;
        margin-left: .5rem;

        &:hover,
        &:focus {
            color: var(--bs-white);
            background-color: var(--bs-primary);
        }
    }
}

/*Pagination*/
.page-item.active {
    .page-link {

        &:focus,
        &:hover {
            cursor: default;
        }
    }
}

/*Sidebar*/
.sidebar {
    float: left !important;
    min-height: 100vh !important;
    padding-left: .75rem;
    padding-right: .75rem;
    padding-top: .5rem;

    .btn {
        padding: .5rem;
        width: 2.75rem;
    }

    .row-cols-1 {
        width: 20em;
    }
}

.sidebar-border {
    border-right: 1px solid var(--bs-border-color);
}

/*Tables*/
/*@include media-breakpoint-down(md) {
  .table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}*/

.table {
    margin-bottom: 0.5rem;

    tbody th {
        padding-bottom: 0;
    }
}

.table-bordered {

    tr,
    th,
    td {
        border: none;
        vertical-align: middle;
    }

    thead tr {
        border-bottom: 1px solid var(--bs-border-color);
    }

    tbody tr {
        border-bottom: 1px solid var(--bs-border-color);
        border-left: 1px solid var(--bs-border-color);
        border-right: 1px solid var(--bs-border-color);
    }
}

/*Toasts*/
.toast {
    font-size: 1rem;
}

.toast-danger {
    background-color: var(--bs-danger-bg-subtle);
    border-color: var(--bs-danger-border-subtle);
    color: var(--bs-danger-text-emphasis);
}

.update-toast {
    padding-top: 6rem !important;
}

/*KW: Temporary workaround until BS redesigns table variants*/
.table-nested-striped {
    --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type: var(--bs-table-striped-bg);
}

th[sortable] {
    cursor: pointer;

    &.asc::after {
        content: "\F235"; //caret-up-fill
        /*content: "\F57B"; //sort-up*/
        /*content: "\F57A"; //sort-up-alt*/
        font-family: "bootstrap-icons";
        vertical-align: bottom;
    }

    &.desc::after {
        content: "\F229"; //caret-down-fill
        /*content: "\F575"; //sort-down*/
        /*content: "\F574"; //sort-down-alt*/
        font-family: "bootstrap-icons";
        vertical-align: bottom;
    }
}

.td-text-truncate {
    display: table;
    table-layout: fixed;
    width: 100%
}

/*Tooltip*/
@include media-breakpoint-up(md) {
    .tt-nowrap .tooltip-inner {
        max-width: none;
        white-space: nowrap;
    }

    .tt-wrap .tooltip-inner {
        max-width: none;
        word-wrap: break-word;
        white-space: normal;
    }
}

/*Validation*/
.ng-dirty,
.ng-touched {
    &.ng-invalid {
        border-color: var(--bs-danger);
    }
}

/*Zendesk*/
.image-zd {
    max-height: 1.25rem;
    padding-bottom: 0.25rem;
}
