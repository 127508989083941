/*Import order matters*/
@import "bootstrap/scss/_functions";

@import "scss/_ds-custom-variables.scss";

@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_variables-dark";

@import "scss/_ds-custom-themes.scss";

@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons";

@import "scss/_ds-custom.scss";
